<template>
  <section class="financial-preview-wrapper">


    <b-row class="financial-preview">

      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
      >
        <b-card>

          <!-- Button: CLose -->
          <b-button
              variant="outline-secondary"
              class="mb-75"
              block
              @click="closeDoc"
          >
            <feather-icon
                icon="XCircleIcon"
                class="mr-50"
            />
            Փակել
          </b-button>

          <v-select
              @input="getCheckups"
              v-model="multipleValues"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="optionsMultiple"
              :reduce="prefix => prefix.id"
              multiple
              class="mb-1"
          />

          <div class="radios-container">
            <b-form-checkbox
                @change="changeFilter"
                v-model="withNoOpinion"
                :value="true"
                class="custom-control-success"
            >
              Անսարք ճանաչվածներ
            </b-form-checkbox>
<!--            <b-form-checkbox-->
<!--                @change="changeFilter"-->
<!--                v-model="withCheckNo"-->
<!--                :value="true"-->
<!--                class="custom-control-success"-->
<!--            >-->
<!--              Կտրոնով-->
<!--            </b-form-checkbox>-->
<!--            <b-form-checkbox-->
<!--                @change="changeFilter"-->
<!--                v-model="withoutCheckNo"-->
<!--                :value="true"-->
<!--                class="custom-control-success"-->
<!--            >-->
<!--              Առանց կտրոն-->
<!--            </b-form-checkbox>-->
          </div>

          <template v-if="userType === 'ADMIN' || userType === 'VIEWER'">
            <v-select
                @input="changeCompany"
                placeholder="Կազմակերպություն"
                id="vue-select"
                :reduce="prefix => prefix.id"
                v-model="filters.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companies"
                class="mb-1"
            />
          </template>

          <v-select
              @input="updateFinanceRange"
              placeholder="Ժամանակահատված"
              id="vue-select-ranges"
              v-model="financeRanges"
              :reduce="prefix => prefix.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rangesSelect"
              class="mb-1"
          />

          <div class="taxRangeDates">
            <div class="taxRangeDateItem">
              <b-form-input
                  v-model="financeForm.startDay"
                  id="startDay"
                  type="date"
                  placeholder="Սկիզբ"
              />
            </div>
            <div class="taxRangeDateItem">
              <b-form-input
                  v-model="financeForm.endDay"
                  id="endDay"
                  type="date"
                  placeholder="Ավարտ"
              />
            </div>
          </div>


          <!-- Button: Send Invoice -->
          <b-button
              variant="success"
              class="mb-75 mt-1"
              block
              @click="getCheckups"
          >
            <feather-icon
                icon="SearchIcon"
                class="mr-50"
            />
            <span>Որոնել</span>
          </b-button>

          <!-- Button: Send Invoice -->
          <b-button
              variant="success"
              class="mb-75 mt-1"
              block
              @click="exportExcel"
          >
            <feather-icon
                icon="DownloadIcon"
                class="mr-50"
            />
            <span>Բեռնել</span>
          </b-button>

        </b-card>
      </b-col>

      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            no-body
            class="financial-preview-card"
            ref="document"
        >
          <!-- Header -->
          <b-card-body class="pb-0">
            <table data-cols-width="5,30,25,10,10,10" id="headerTable" class="financial-table">
              <thead>
              <tr>
                <th data-b-a-s="thin" colspan="6"></th>
              </tr>
              <tr v-if="company">
                <th data-b-a-s="thin" data-f-bold="true" data-a-h="center" colspan="6">
                  {{ company.name }} {{ company.region }},
                  {{ company.county }}, {{ company.street }},
                  {{ company.address }}
                </th>
              </tr>
              <tr>
                <th data-b-a-s="thin"></th>
                <th data-b-a-s="thin">
                  <template>
                    <span>{{ financeForm.startDay | formatDayFinancial }} - {{
                        financeForm.endDay | formatDayFinancial
                      }}</span>
                  </template>
                </th>
                <th data-b-a-s="thin" colspan="4"></th>
              </tr>
              <tr>
                <th data-b-a-s="thin">Հ/Հ</th>
                <th data-b-a-s="thin">Անուն Ազգանուն</th>
                <th data-b-a-s="thin">Մեքենա</th>
                <th data-b-a-s="thin">Գր. համարանիշ</th>
                <th data-b-a-s="thin">Հեռախոսահամար</th>
                <th data-b-a-s="thin">Գումար</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(checkup, index) in items">
                <td data-b-a-s="thin">{{ index + 1 }}</td>
                <td data-b-a-s="thin">{{ checkup.ownerStatus.Owner }}</td>
                <td data-b-a-s="thin">{{ checkup.car }} {{ checkup.car_model }}</td>
                <td data-b-a-s="thin">{{ checkup.CarRegNo }}</td>
                <td data-b-a-s="thin">{{ checkup.Phone }}</td>
                <td data-b-a-s="thin">{{ checkup.Sum }}</td>
              </tr>
              <tr>
                <td data-b-a-s="thin" colspan="5" class="border-0"></td>
                <td data-b-a-s="thin">{{ totalSum }}</td>
              </tr>
              <tr>
                <td class="border-0" colspan="6"></td>
              </tr>
              <tr>
                <td class="border-0" colspan="6"></td>
              </tr>
              <tr>
                <td class="border-0" colspan="6"></td>
              </tr>
              <tr>
                <td class="border-0"></td>
                <td class="border-0">Տնօրեն</td>
                <td class="border-0" colspan="4"></td>
              </tr>
              </tbody>
            </table>

          </b-card-body>

        </b-card>
      </b-col>


    </b-row>
  </section>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import { BRow, BCol, BCard, BCardBody, BButton, BFormInput, BFormCheckbox } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import store from '@/store'
import vSelect from 'vue-select'
import companyModule from '@/store/companies/companyModule'
import TableToExcel from '@linways/table-to-excel'

let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

export default {
  name: 'financial-doc',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormInput,
    BFormCheckbox,
    DateRangePicker,
    vSelect
  },
  data() {
    return {
      items: [],
      withNoOpinion: false,
      withCheckNo: true,
      withoutCheckNo: true,
      financeRanges: todayStart + ' - ' + todayEnd,
      financeForm: {
        startDay: this.$options.filters.formatDayInput(todayStart),
        endDay: this.$options.filters.formatDayInput(todayEnd),
      },
      multipleValues: [0, 1, 2],
      optionsMultiple: [
        {
          id: 0,
          label: '6 ամսից'
        },
        {
          id: 1,
          label: '1 տարուց'
        },
        {
          id: 2,
          label: '2 տարուց'
        }
      ],
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      filters: {
        company: null
      },
      dateRange: {
        startDate: todayStart,
        endDate: todayEnd
      },
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
      },
    }
  },
  computed: {

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].financial_checkups
    },

    userType() {
      return atob(this.$store.state.user_type)
    },

    companies() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
        ...e,
        label: e.name,
        id: e._id
      }))
    },

    company: {
      get() {
        if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
          return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
        } else if (this.userType === 'TECHNICAL_SERVICE_OWNER') {
          return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies[0]
        }
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
      },
      set(data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_COMPANY', data)
      }
    },

    dateRanges() {
      // Today
      let today = new Date()

      let allStart = new Date(this.firstCheckupDate)

      let todayStart = new Date()
      let todayEnd = new Date()

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let currentMonth = today.getMonth()
      let currentYear = today.getFullYear()
      let thisQuarterStart
      let thisQuarterEnd
      let lastQuarterStart
      let lastQuarterEnd
      if (currentMonth >= 0 && currentMonth < 3) {
        thisQuarterStart = new Date(today.getFullYear(), 0, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 2, 31)

        lastQuarterStart = new Date(today.getFullYear() - 1, 9, 1)
        lastQuarterEnd = new Date(today.getFullYear() - 1, 11, 31)
      } else if (currentMonth >= 3 && currentMonth < 6) {
        thisQuarterStart = new Date(today.getFullYear(), 3, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastQuarterStart = new Date(today.getFullYear(), 0, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 2, 31)
      } else if (currentMonth >= 6 && currentMonth < 9) {
        thisQuarterStart = new Date(today.getFullYear(), 6, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 8, 30)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      } else if (currentMonth >= 9 && currentMonth < 12) {
        thisQuarterStart = new Date(today.getFullYear(), 9, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }

      let thisSemesterStart
      let thisSemesterEnd
      let lastSemesterStart
      let lastSemesterEnd
      if (currentMonth >= 0 && currentMonth < 5) {
        thisSemesterStart = new Date(today.getFullYear(), 0, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastSemesterStart = new Date(today.getFullYear() - 1, 6, 1)
        lastSemesterEnd = new Date(today.getFullYear() - 1, 11, 31)

      } else {
        thisSemesterStart = new Date(today.getFullYear(), 6, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastSemesterStart = new Date(today.getFullYear(), 0, 1)
        lastSemesterEnd = new Date(today.getFullYear(), 5, 30)
      }

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      return {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': [allStart, todayEnd],
      }
    },

    rangesSelect() {
      const data = []
      for (let key in this.dateRanges) {
        let temp = {}
        temp.label = key
        let start = this.dateRanges[key][0]
        start.setHours(0, 0, 0, 0)
        let end = this.dateRanges[key][1]
        end.setHours(23, 59, 59, 59)
        temp.value = start + ' - ' + end
        data.push(temp)
      }
      return data
    },

    totalSum() {
      return this.items.map(item => item.Sum)
          .reduce((prev, curr) => prev + curr, 0)
    },

    excel_name() {
      return this.company.name + ', ' + this.$options.filters.dateHy(this.dateRange.startDate) + '-' + this.$options.filters.dateHy(this.dateRange.endDate) + '.xlsx'
    }

  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    this.getCheckups()
    this.getCompanies()
  },

  destroyed() {
    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'financial')
  },

  methods: {

    getCompanies() {
      if (this.userType === 'TECHNICAL_SERVICE_OWNER' || this.userType === 'ADMIN' || this.userType === 'VIEWER') {
        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
      }

    },

    closeDoc() {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'financial')
    },

    updateFinanceRange() {
      let dates = this.financeRanges
      dates = dates.split(' - ')
      let startDate = dates[0]
      let endDate = dates[1]
      this.financeForm = {
        startDay: this.$options.filters.formatDayInput(startDate),
        endDay: this.$options.filters.formatDayInput(endDate),
      }

      this.getCheckups()
    },

    getCheckups() {
      this.$vs.loading()
      let queryString = ''
      let dateTimeStart = new Date(this.financeForm.startDay)
      let dateTimeEnd = new Date(this.financeForm.endDay)
      dateTimeStart.setHours(0, 0, 0, 0)
      dateTimeEnd.setHours(23, 59, 59, 59)

      queryString += `startDate=${dateTimeStart}&endDate=${dateTimeEnd}`
      queryString += '&nextCheckupDays=' + JSON.stringify(this.multipleValues)

      if ((this.userType === 'ADMIN' || this.userType === 'VIEWER') && this.company) {
        queryString += '&company_id=' + this.company._id
      }

      if (this.withNoOpinion) {
        queryString += '&withNoOpinion=' + this.withNoOpinion
      }

      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getFinancialCheckups', queryString)
          .then(() => {
            this.$vs.loading.close()
            this.changeFilter()
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    changeCompany() {
      const index = this.companies.findIndex(e => e.id === this.filters.company)
      if (index >= 0) {
        this.company = this.companies[index]
      }
      this.getCheckups()
    },

    exportExcel() {
      TableToExcel.convert(document.getElementById('headerTable'), {
        name: this.excel_name,
        sheet: {
          name: 'Ֆինանսական հաշվետվություն'
        }
      })
    },
    changeFilter() {
      if (this.withCheckNo && this.withoutCheckNo) {
        this.items = [...this.checkups]
      } else if (this.withCheckNo && !this.withoutCheckNo) {
        this.items = [...this.checkups.filter(c => c.CheckNo !== null && c.CheckNo !== '')]
      } else if (!this.withCheckNo && this.withoutCheckNo) {
        this.items = [...this.checkups.filter(c => c.CheckNo === null || c.CheckNo === '')]
      } else {
        this.items = []
      }
    },

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.financial-preview-card {
  max-height: calc(var(--vh, 1vh) * 100 - 10rem);
  min-height: calc(var(--vh, 1vh) * 100 - 10rem);
  overflow-y: scroll;

  * {
    color: #000
  }
}

.vue-daterange-picker {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.vue-daterange-picker .show-ranges.hide-calendars {
  width: 250px !important;
  min-width: 250px !important;
}

.financial-table {
  width: 100%;
  color: #000;

  thead, tbody {
    tr {
      opacity: 1;

      th, td {
        border: 1px solid #000;
        line-height: 10px;
        height: 25px;
        text-align: center;

      }
    }
  }
}

.radios-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0;

  .custom-checkbox {
    margin-bottom: 10px;
  }
}
</style>
